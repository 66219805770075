@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-sm text-link font-bold hover:text-link/75 underline transition-all duration-300 ease-in-out;
  }

  a:focus, a:active {
    @apply text-link/75;
  }
  *|*:link {
    @apply text-link;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}